import starvingCapybara from "@/assets/capybara-images/starving.png";
import famishedCapybara from "@/assets/capybara-images/famished.png";
import hungryCapybara from "@/assets/capybara-images/hungry.png";
import fullCapybara from "@/assets/capybara-images/full.png";
import nourishedCapybara from "@/assets/capybara-images/nourished.png";
import starvingWallpaper from "@/assets/capybara-images/starvingWallpaper.png";
import famishedWallpaper from "@/assets/capybara-images/famishedWallpaper.png";
import hungryWallpaper from "@/assets/capybara-images/HungryWallpaper.png";
import fullWallpaper from "@/assets/capybara-images/fullWallpaper.png";
import nourishedWallpaper from "@/assets/capybara-images/nourishedWallpaper.png";
import famishedLeague from "@/assets/capybara-images/famishedLeague.png";
import hungryLeague from "@/assets/capybara-images/HungryLeague.png";
import starvingLeague from "@/assets/capybara-images/starvingLeague.png";
import fullLeague from "@/assets/capybara-images/FullLeague.png";
import nourishedLeague from "@/assets/capybara-images/NourishedLeague.png";
import BoostIcon from "@/assets/icons/boost.svg?react";
import EarnIcon from "@/assets/icons/earn.svg?react";
import FeedIcon from "@/assets/icons/feed.svg?react";
import LeaguesIcon from "@/assets/icons/leagues.svg?react";
import ReferralIcon from "@/assets/icons/referral.svg?react";
import earnWallpaper from "@/assets/capybara-images/earnWallpaper.png";
import boostWallpaper from "@/assets/capybara-images/boostWallpaper.png";
import leaderboardWallpaper from "@/assets/capybara-images/leaderboardWallpaper.png";
import referralWallpaper from "@/assets/capybara-images/referralWallpaper.png";

import slide1 from "@/assets/layout/stories/onboarding/Slide1.png";
import slide2 from "@/assets/layout/stories/onboarding/Slide2.png";
import slide3 from "@/assets/layout/stories/onboarding/Slide3.png";
import slide4 from "@/assets/layout/stories/onboarding/Slide4.png";
import slide5 from "@/assets/layout/stories/onboarding/Slide5.png";
import slide6 from "@/assets/layout/stories/onboarding/Slide6.png";
import slide7 from "@/assets/layout/stories/onboarding/Slide7.png";
import slide8 from "@/assets/layout/stories/suiOnboarding/Slide1.png";
import slide9 from "@/assets/layout/stories/suiOnboarding/Slide2.png";
import slide10 from "@/assets/layout/stories/suiOnboarding/Slide3.png";
import slide11 from "@/assets/layout/stories/suiOnboarding/Slide4.png";
import slide12 from "@/assets/layout/stories/suiOnboarding/Slide5.png";
import slide13 from "@/assets/layout/stories/onboardingZlog/Slide1.png";
import slide14 from "@/assets/layout/stories/onboardingZlog/Slide2.png";
import slide15 from "@/assets/layout/stories/nfts/slide1.png";
import slide16 from "@/assets/layout/stories/nfts/slide2.png";
import { Earn } from "./views/Earn";
import { Feed } from "./views/Feed";
import { Boost } from "./views/Boost";
import { Referral } from "./views/Referral";
import { Leaderboard } from "./views/Leaderboard";
import { ComponentType } from "react";
import { StorySet } from "./store/StoryManager";
import { CapybaraNftCard } from "./components/CapybaraNftCard";

export const coinbase = "https://api.coinbase.com/v2/";
export const cdnStage = "https://aws-cdn.kapybara.xyz";
export const cdnProduction = "https://cdn.capybara.vip";
export const apiStage = "https://aws-api.kapybara.xyz";
export const apiProduction = "https://api.capybara.vip";
export const settingsStage = "https://aws-settings.kapybara.xyz";
export const settingsProduction = "https://api.capybara.vip";

export const PACKAGE_ADDRESS =
  "0x99a1800ce832f92814fac7f0b5ed1e98317a33d5325b2498d6c62356b08a39f4";
export const REGISTRY_ADDRESS =
  "0x52e3cef51aaa1f29f05b7d8afa4c542285225acd3339804db65e33635ebd80eb";
export const TREASURY_ADDRESS =
  "0x4688cc040093c8cd7db60090fdefacc1c6af947fb9555ad5919efa8ed8853da4";

export const isProdUrl = window.location.href.includes(
  "https://app.capybara.vip"
);

export const inviteShareLink = `https://t.me/getcapybot/now?startapp={userId}`;

export const capybaraLevels = [
  {
    levelThresholds: 1000,
    img: starvingCapybara,
    backgroundColor: "linear-gradient(180deg, #4DA2FF 0%, #C0E6FF 100%)",
    walletBackgroundColor: "#82bfff",
    wallpaper: {
      src: starvingWallpaper,
      label: "starvingWallpaper",
    },
    leagueFace: starvingLeague,
    leagueName: "starving",
    headerColor: "#4fa3ff",
  },
  {
    levelThresholds: 2000,
    img: famishedCapybara,
    backgroundColor: "linear-gradient(180deg, #558FFF 0%, #C0E6FF 100%)",
    wallpaper: {
      src: famishedWallpaper,
      label: "famishedWallpaper",
    },
    walletBackgroundColor: "#7ba9ff",
    leagueFace: famishedLeague,
    leagueName: "famished",
    headerColor: "#5790fe",
  },
  {
    levelThresholds: 3000,
    img: hungryCapybara,
    backgroundColor: "linear-gradient(180deg, #5B7FFF 0%, #C0E6FF 100%)",
    wallpaper: {
      src: hungryWallpaper,
      label: "hungryWallpaper",
    },
    walletBackgroundColor: "#7f9eff",
    leagueFace: hungryLeague,
    leagueName: "hungry",
    headerColor: "#5d81ff",
  },
  {
    levelThresholds: 4000,
    img: fullCapybara,
    backgroundColor: "linear-gradient(180deg, #8287FF 0%, #C0E6FF 100%)",
    wallpaper: {
      src: fullWallpaper,
      label: "fullWallpaper",
    },
    walletBackgroundColor: "#9ca4ff",
    leagueFace: fullLeague,
    leagueName: "full",
    headerColor: "#8389ff",
  },
  {
    levelThresholds: 5000,
    img: nourishedCapybara,
    backgroundColor:
      "linear-gradient(179.98deg, #BC93FF 0.02%, #C0E6FF 98.86%)",
    wallpaper: {
      src: nourishedWallpaper,
      label: "nourishedWallpaper",
    },
    walletBackgroundColor: "#b6a2f8",
    leagueFace: nourishedLeague,
    leagueName: "nourished",
    headerColor: "#bc94ff",
  },
];

interface MenuItem {
  path: string;
  Icon?: ComponentType;
  text?: string;
  isHideMenu?: boolean;
  component:
    | typeof Feed
    | typeof Earn
    | typeof Boost
    | typeof Leaderboard
    | typeof CapybaraNftCard
    | typeof Referral;
  backgroundColor: (level: number) => string;
  wallpaper: (level: number) => string;
  headerColor: (level: number) => string;
}

export const menuItems: MenuItem[] = [
  {
    path: "/",
    Icon: FeedIcon,
    text: "Feed",
    component: Feed,
    backgroundColor: (level: number) => capybaraLevels[level].backgroundColor,
    wallpaper: (level: number) => capybaraLevels[level].wallpaper.src,
    headerColor: (level: number) => capybaraLevels[level].headerColor,
  },
  {
    path: "/earn",
    Icon: EarnIcon,
    text: "Earn",
    component: Earn,
    backgroundColor: () =>
      "linear-gradient(203.17deg, #1A64CE 34.76%, #8588DA 72.02%)",
    wallpaper: () => earnWallpaper,
    headerColor: () => "#4da2ff",
  },
  {
    path: "/boost",
    Icon: BoostIcon,
    text: "Boost",
    component: Boost,
    backgroundColor: () =>
      "linear-gradient(203.17deg, #1A64CE 34.76%, #8588DA 72.02%)",
    wallpaper: () => boostWallpaper,
    headerColor: () => "#4da2ff",
  },
  {
    path: "/leaderboard",
    Icon: LeaguesIcon,
    text: "Leaderboard",
    component: Leaderboard,
    backgroundColor: () =>
      "linear-gradient(203.17deg, #1A64CE 34.76%, #8588DA 72.02%)",
    wallpaper: () => leaderboardWallpaper,
    headerColor: () => "#4da2ff",
  },
  {
    path: "/referral",
    Icon: ReferralIcon,
    text: "Referral",
    component: Referral,
    backgroundColor: () =>
      "linear-gradient(203.17deg, #1A64CE 34.76%, #8588DA 72.02%)",
    wallpaper: () => referralWallpaper,
    headerColor: () => "#4da2ff",
  },
];

export const stories: StorySet[] = [
  {
    id: "onboarding",
    stories: [
      {
        id: "1",
        url: slide1,
        type: "image",
        duration: 5000,
      },
      {
        id: "2",
        url: slide2,
        type: "image",
        duration: 5000,
      },
      {
        id: "3",
        url: slide3,
        type: "image",
        duration: 5000,
      },
      {
        id: "4",
        url: slide4,
        type: "image",
        duration: 5000,
      },
      {
        id: "5",
        url: slide5,
        type: "image",
        duration: 5000,
      },
      {
        id: "6",
        url: slide6,
        type: "image",
        duration: 5000,
      },
      {
        id: "7",
        url: slide7,
        type: "image",
        duration: 5000,
      },
    ],
  },
  {
    id: "onboardingSui",
    stories: [
      {
        id: "1",
        url: slide8,
        type: "image",
        duration: 5000,
      },
      {
        id: "2",
        url: slide9,
        type: "image",
        duration: 5000,
      },
      {
        id: "3",
        url: slide10,
        type: "image",
        duration: 5000,
      },
      {
        id: "4",
        url: slide11,
        type: "image",
        duration: 5000,
      },
      {
        id: "5",
        url: slide12,
        type: "image",
        duration: 5000,
      },
    ],
  },
  {
    id: "onboardingZlog",
    stories: [
      {
        id: "1",
        url: slide13,
        type: "image",
        duration: 5000,
      },
      {
        id: "2",
        url: slide14,
        type: "image",
        duration: 5000,
      },
    ],
  },
  {
    id: "nft",
    stories: [
      {
        id: "1",
        url: slide15,
        type: "image",
        duration: 5000,
      },
      {
        id: "2",
        url: slide16,
        type: "image",
        duration: 5000,
      },
    ],
  },
];
